import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import DataService from "../services/api/data_services";
import Layout from "../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import Megaview from "../templates/MegaView";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import VerticalLinearStepper from "../templates/VerticalStepper";
import BackArrowIcon from "../assets/Images/accordian.svg";
import Button from "../components/Button";
import MiniImageBannerBgImg12 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
import RightArrow from "../assets/Icons/go.svg";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import python from "../assets/Images/TechnologyUsed/python.png";
import TensorFlow from "../assets/Images/TechnologyUsed/TensorFlow.png";
import Keras from "../assets/Images/TechnologyUsed/Keras.png";
import OpenCV from "../assets/Images/TechnologyUsed/OpenCv.png";
import ScikitLearn from "../assets/Images/TechnologyUsed/Scikit-learn.png";
import Pandas from "../assets/Images/TechnologyUsed/Pandas.png";
import Theano from "../assets/Images/TechnologyUsed/Theano.png";
import PyTorch from "../assets/Images/TechnologyUsed/PyTorch.png";
import { BASE_SITE_URL } from "../shared/constants";
//Click To View Mega ViewCarousal
import ClickToViewMegaViewCarousal from "../templates/ClickToViewMegaViewCarousal";
import ClickToViewMegaViewCarousalImg1 from "../assets/Images/ClickToViewMegaViewCarousal/Lean_startup_mindset.png";
import ClickToViewMegaViewCarousalImg2 from "../assets/Images/ClickToViewMegaViewCarousal/Contact_and_first_proposal.png";
import ClickToViewMegaViewCarousalImg3 from "../assets/Images/ClickToViewMegaViewCarousal/Product_Workshops_with_the_team.png";
import ClickToViewMegaViewCarousalImg4 from "../assets/Images/ClickToViewMegaViewCarousal/Final_proposal_and_release_plan.png";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
import BannerBgImage from "../assets/Images/machine-learning-banner-image.png";
import scrollImage from "../assets/Images/machineLearning/machine_learning_LS@2x.png";

//Mini Image Banner1
import MiniImageBanner1 from "../templates/MiniImageBanner1";

//Info View
import Infoview from "../templates/InfoView";

//Miniview
import Miniview from "../templates/MiniView";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";

//Mini Image Banner
import MiniImageBanner from "../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../assets/Images/contact_us_bg.png";

//BlurCardList
import BlurCardList from "../templates/BlurCardList";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";

//Six Hover Cards
import SixPlainCards from "../templates/SixPlainCards";
import HoverCardWithBtnIcon1 from "../assets/Icons/Deep-learning.svg";
import HoverCardWithBtnIcon2 from "../assets/Icons/neural-network.svg";
import HoverCardWithBtnIcon3 from "../assets/Icons/decision-trees.svg";
import HoverCardWithBtnIcon4 from "../assets/Icons/spatial-networks-&-spatial-analysis.svg";
import HoverCardWithBtnIcon5 from "../assets/Icons/Python-development.svg";

// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";

import ogimage from "../assets/Images/ogImages/OG_Machine-Learning.png";
const getStepper = () => {
  return [
    "Customer experience",
    "Personalisation",
    "Churn and retention",
    "Sentiment analysis",
  ];
};
const getStepper1 = () => {
  return [
    "Data-driven growth",
    "Pricing and promotional models",
    "Automated product quality assurance",
  ];
};
const getStepper2 = () => {
  return [
    "Process automation",
    "Production management",
    "Performance management",
  ];
};
const getStepper3 = () => {
  return [
    "Financial forecasting and investment allocation",
    "Fraud detection",
    "Credit risk assessment",
  ];
};

const MachineLearning = () => {
  const stepper = getStepper();
  const stepper1 = getStepper1();
  const stepper2 = getStepper2();
  const stepper3 = getStepper3();
  const [limit, setLimit] = useState(6);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [page, setPage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };
  //Movie Slider
  const mobMovieSlider = {
    imageUrl: scrollImage,
  };
  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - Machine Learning %26 AI"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - Machine Learning %26 AI&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${limit}&page=${page}&type=Client Story&service_type=PD - Machine Learning %26 AI&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    // bnrImgTitle: "Go Digital For More!",
    bnrImgTitle: <div>Machine Learning</div>,
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        Add Value with Data-Driven
        <br />
        <span className="red-line3letter"> Bus</span>iness Decisions
      </div>
    ),
    tag: "h2",
    InfoviewContent: (
      <>
        With advanced analytics services, make an insight-led decision for your
        business. If your business is sitting on a mountain of data, let us help
        you unleash its true potential for your business. We help you optimise
        and automate business processes, customize client servicing operations
        through our cross-functional machine learning team. Make better business
        decisions with the power of our AI, ML and advanced analytics services.
      </>
    ),
  };
  /**
   * Miniview
   */

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  const miniviewDataCaseStudy = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    tooltip: "yes",
    tip: "TAKE A LOOK",
    route: "/contact-us",
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle: "Let’s Build Your MVP",
    HoverCardWithBtntitle1: "Deep Learning",
    HoverCardWithBtntitle2: "Neural Network",
    HoverCardWithBtntitle3: "Decision Trees",
    HoverCardWithBtntitle4: "Spatial Networks and Spatial Analysis",
    HoverCardWithBtntitle5: "Python Development",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContentClass: "btncontent mt-10",
    HoverCardWithBtnContent1:
      "At Digiryte, we deliver a comprehensive suite of AI customisation strategies that will help you to tap into deep learning and visual AI to deliver new, innovative digital experiences to your customers to drive engagement and business growth.",
    HoverCardWithBtnContent2:
      "In order to remain competitive in the market, a genetic algorithm trained neural network is used to identify strategy or combinations of strategies to provide directions to managers to improve performance.",
    HoverCardWithBtnContent3:
      "The Decision Tree method is generally used for predictive modelling. It uses statistics, data mining and machine learning to predict the potential outcome of business decisions. It is an excellent tool to help you understand the risks and rewards associated with each possible course of action.",
    HoverCardWithBtnContent4:
      "Through Spatial Networks we help our clients identify opportunities, reduce uncertainty and mitigate risks through verifiable, ground-truth data that is attainable through many open source methods.",
    HoverCardWithBtnContent5:
      "One of the most widely used programming languages, Python is a great platform for anyone looking to develop a product. This platform gives you the freedom to upgrade in the future as it can easily interact with other languages.",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    whiteCard: "yes",
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
      tip: "GET IN TOUCH",
      tooltip: "yes",
    },
  };

  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner3Data = {
    Id: "minibanner1",
    tag: "h3",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us",
    HoverLinkContent: "Contact Us",
    PlainContent: "to audit your product",
    route: "/contact-us",
  };
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg12,
    tag: "h3",
    tooltip: "yes",
    tip: "TAKE A LOOK",
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    underlineClass: "black-line",
  };
  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Click To View Mega View Carousal
   */

  const ClickToViewMegaViewCarousalData = {
    data: [
      {
        title: "STEP 1: Lean startup approach",
        download: "no",
        bottom: "Collaboration Process",
        displayFIndoutMore: "no",
        card1data: {
          meta_title: "Lean startup approach",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Having a business mind is a skill. No skill is developed by tactics alone. Developing a skill at a very elite level requires behavioural changes, mindset shifts and expanding your personality. And it can take years to develop it. If you blindly follow tactics without the proper mindset, you won’t be executing those tactics the way they ought to be executed.  Let's take an example: Build, Iterate and Measure are the key concepts of Lean startup. There is nothing wrong with this concept. But let’s dig a few layers deeper to really understand what it means. If you directly apply this concept it will not work.",
          mega_view_image: ClickToViewMegaViewCarousalImg1,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "lean-mindset-to-launch-a-successful-software-product",
          slug_type: "article",
        },
      },
      {
        title: "STEP 2: Contact and first proposal",
        card1data: {
          meta_title: "Contact and first proposal",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "After you contact Digiryte, we gather as much information possible about the product idea and spend our time understanding your business needs and identifying your potential customers to ensure we build a platform which will be familiar to your potential users and stands ahead of your competitors. After considering all the factors, we aim to respond in the next 2-3 business days with a pre-proposal which usually consists of a median SDLC ballpark estimate, the bespoke development approach we created based on your requirements and the various steps explaining our working methodology.",
          mega_view_image: ClickToViewMegaViewCarousalImg2,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "digiryte-s-proposal-and-working-methodology",
          slug_type: "article",
        },
      },
      {
        title: "STEP 3: Product workshop and consulting",
        card1data: {
          meta_title: "Product workshop and consulting",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "Whether you have an idea for building a mobile app, or you need to build complex internal software in a large corporation, all great digital products should be built from a solid foundation. This means gaining a deep understanding of your business needs, what problem or opportunity you’re trying to solve, an idea of your users behaviours and preferences and the value that the product will add.",
          mega_view_image: ClickToViewMegaViewCarousalImg3,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "4-steps-of-digiryte-s-successful-product-workshop-methodology",
          slug_type: "article",
        },
      },
      {
        title: "STEP 4: Final proposal and release plan",
        card1data: {
          meta_title: "Final proposal and release plan",
          type: "ARTICLE",
          download: "no",
          height: "294px",
          primary_description_1:
            "We position ourselves uniquely in the market by taking a higher approach to creating a win-win situation for us and the client to avoid major pitfalls during the development. Having worked closely with the University of Manchester, over time, we have researched and created various methodologies which help our clients and us understand the project workflow better and help us meet expectations, costs and timelines more accurately. Based on the medium (mobile, web or both) and scope of the project, we usually narrow down at least two to three possible methodologies which best suit the flow of the project.",
          mega_view_image: ClickToViewMegaViewCarousalImg4,
          MegaviewFooterLabel: "ARTICLE",
          MegaviewButtonLabel: "Find out More",
          MegaviewButtonColor: "primary",
          MegaviewContentClassname:
            "megaview-card-content megaview-carousal-height ",
          MegaviewImageClassname: "megaview-carousal-height ",
          slug: "final-proposal-and-release-plan-methodology-of-digiryte",
          slug_type: "article",
        },
      },
    ],
  };
  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };
  return (
    <Layout
      seo={{
        title: "What we do: Machine Learning | Digiryte",
        ogtitle: "What we do: Machine Learning | Digiryte",
        description:
          "Make better business decisions with the power of our Artificial Intelligence, Machine Learning and advanced analytics services.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/machine-learning`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-70 body-container">
        <h3 className="title-header ttc">
          <span className="red-line3letter">Our</span> Skills
        </h3>
      </div>
      <div className="mt-50"></div>
      <SixPlainCards SixHoverCardData={SixHoverCardData} />
      <div className="mt-70 body-container">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Our</span> Expertise
        </h2>
        <div className="mt-50 info-view-content">
          With our AI , machine learning and advanced analytics services, our
          clients can leverage business process automation technologies to drive
          operational efficiency. Our expert team will empower you to speed up
          your decision-making process to accelerate processes and workflows
          across the organisation.
        </div>
      </div>
      <div className="body-container">
        <Grid container className="mt-50" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Sal</span>es & Customer Service
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper} />
          </Grid>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Mar</span>keting & Products
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper1} />
          </Grid>
        </Grid>
      </div>
      <div className="body-container">
        <Grid container className="mt-50" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Ope</span>rations & HR
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper2} />
          </Grid>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Str</span>ategy & Risk
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper3} />
          </Grid>
        </Grid>
      </div>
      <div className="mt-70">
        <MiniImageBanner miniImageBannerData={MiniImageBanner3Data} />
      </div>
      <div className="mt-70 body-container">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Our</span> Approach
        </h2>
      </div>{" "}
      <div className="mt-50" />
      <ClickToViewMegaViewCarousal
        ClickToViewMegaViewCarousalData={ClickToViewMegaViewCarousalData}
      />
      <div className="mt-70 body-container">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Tec</span>hnology We Use
        </h2>

        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
        <Grid container className="mt-50 mbl-hide" direction="row" spacing={1}>
          <Grid item md={3}>
            <img alt="python" src={python} />{" "}
            <p style={{ fontSize: "16px" }}>Python</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img src={TensorFlow} alt="TensorFlow" />{" "}
            <p style={{ fontSize: "16px" }}>TensorFlow</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img src={Keras} alt="Keras" />{" "}
            <p style={{ fontSize: "16px" }}>Keras</p>
          </Grid>
          <Grid item md={3}>
            {" "}
            <img src={OpenCV} alt="OpenCV" />{" "}
            <p style={{ fontSize: "16px" }}>OpenCV</p>
          </Grid>
          <br />
          <br />
          <Grid className="mt-50" item md={3}>
            {" "}
            <img src={ScikitLearn} alt="Scikit-learn" />{" "}
            <p style={{ fontSize: "16px" }}>Scikit-learn</p>
          </Grid>
          <Grid className="mt-50" item md={3}>
            {" "}
            <img src={Pandas} alt="Pandas" />{" "}
            <p style={{ fontSize: "16px" }}>Pandas</p>
          </Grid>
          <Grid className="mt-50" item md={3}>
            {" "}
            <img src={Theano} alt="Theano" />{" "}
            <p style={{ fontSize: "16px" }}>Theano</p>
          </Grid>
          <Grid className="mt-50" item md={3}>
            {" "}
            <img src={PyTorch} alt="PyTorch" />{" "}
            <p style={{ fontSize: "16px" }}>PyTorch</p>
          </Grid>
        </Grid>
      </div>
      <div className="mt-50">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h2 className="title-header ttc">
              <span className="red-line3letter">Cli</span>ent Stories
            </h2>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              miniviewData2={miniviewDataCaseStudy}
              total={clientStoryData.length}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          className="mt-60"
          role="presentation"
          onClick={() => {
            setPage(page === 1 ? page + 2 : page + 1);
            setLimit(3);
          }}
          style={{ display: "inline-block" }}
        >
          {clientStoryRawData.has_more && (
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="Load More"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="Load More"
            />
          )}
        </div>
      </div>
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="body-container mt-70">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Oth</span>er Services
        </h2>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default MachineLearning;
